@use './_variables' as *;

.lunar-calendar-description {
  max-width: $character-width;
  margin: 2rem auto;

  .lunar-phases {
    display: flex;
    justify-content: center;
    margin: 2rem auto;
  }

  .phase-info {
    width: 3.75rem;
    margin: .125rem;
    text-align: center;
  }

  .phase-text {
    text-align: center;
    text-transform: capitalize;
    font-size: .875rem;
    line-height: 1.125rem;
  }

  ul {
    padding-inline-start: 1.25rem;

    li {
      margin-top: .5rem;
    }
  }
}

.lunar-calendar-thumbnails {
  display: flex;
  flex-direction: column;
}

.thumbnails-group {

  .thumbnails-title {
    margin: 1rem auto .5rem;
    text-transform: capitalize;
    color: $sixty-seven-percent-gray;
    font-weight: 700;
    text-align: center;
  }

  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

.thumbnail {
  margin: .5rem .75rem;

  a {
    display: block;

    img {
      width: 12.5rem;
      vertical-align: top;

      &:hover {
        box-shadow: $hover-shadow;
      }
    }
  }
}

.lunar-calendar-accompanying-item {
  text-align: center;
}

.lunar-calendar-accompanying-item-image {
  width: 300px;
}
