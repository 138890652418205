@use './variables' as *;

.shop-info {
  max-width: 18.75rem;
  padding: 0 1rem;
  margin: auto;
}

.items-search {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  input {
    width: 94%;
    padding: .5rem;
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
}

.category-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem auto auto;

  &.buttons-length-4 {
    justify-content: space-between;
  }

  .category-button {
    padding: .25rem .5rem;
    border: $border-standard;
    border-radius: $border-radius-small;
    background-color: transparent;
    color: $eighty-percent-gray;

    &:hover,
    &.selected {
      background-color: $eighty-percent-gray;
      color: $white;
    }
  }
}

.shop-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shop-link {
  display: flex;
  flex-direction: column;
  width: $image-dimension-small;
  border: $border-standard;
  border-radius: $border-radius-large;
  margin: 1rem;

  &:hover {
    box-shadow: $hover-shadow;
  }
}

.item-card-image {
  min-height: $image-dimension-small;
  object-fit: cover;
  border-top-left-radius: $border-radius-medium;
  border-top-right-radius: $border-radius-medium;
  border-bottom-width: $border-width-small;
  border-bottom-style: solid;
  border-bottom-color: $border-gray;

  &.postcard.cachalot {
    object-position: -.625rem;
  }

  &.postcard.hippopotamus,
  &.postcard.rhode-island-whale {
    object-position: left;
  }

  &.postcard.great-white-shark {
    object-position: right;
  }
}

.item-card-info {
  padding: 1rem;
  text-align: center;
}

.item-card-title {
  font-weight: 700;
}

.item-card-category {
  margin-bottom: .5rem;
}

.item-card-price {
  font-size: 1.5rem;
}

.item-preorder-text {
  color: $dark-kelly-green;
  font-weight: 700;
}

.item-sold-out {
  color: $dark-red;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

/* Media Queries */

@media (min-width: $breakpoint-sm) {

  .shop-info {
    max-width: initial;
    width: 33.125rem;
  }

  .items-search input {
    width: 17.5rem;
  }

  .category-buttons-container {
    width: 18.75rem;
  }
}
