@use './_variables' as *;

header {
  position: fixed;
  width: 100%;
  margin: auto;
  z-index: 100;
}

.huetown-logo-and-burger-button {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: .5rem 1.125rem;
  background-color: $eighty-percent-gray;
}

.huetown-logo-gray {
  width: $image-dimension-small;
}

.huetown-logo-white {
  width: $image-dimension-xtra-small;
}

.burger-button {
  position: relative;
  cursor: pointer;
  padding: 5px;
  border: none;
  background-color: transparent;

  &.closing-x-button {
    padding: 5px 2px;
  }
}

.burger-bars-container {
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger-bar {
  position: relative;
  width: 25px;
  height: 4px;
  background-color: $white;
  transition: transform .125s;
}

.burger-bar.closing-x-bar {
  width: 30px;

  &:nth-child(1) {
    top: 9px;
    transform: rotate(45deg);
  }

  &:nth-child(2) {
    display: none;
  }

  &:nth-child(3) {
    bottom: 12px;
    transform: rotate(-45deg);
  }
}

nav {
  flex-direction: column;
  background-color: $dark-film;
  backdrop-filter: blur(5px);

  &.mobile-nav-is-closed {
    display: none;
  }

  &.mobile-nav-is-open {
    display: flex;
  }

}

.nav-link-wrapper {
  text-align: center;

  &:nth-of-type(1) {
    border-top: $border-width-small solid $twenty-five-kelly-green-paper;
    border-bottom: $border-width-small solid $huetown-yellow;
  }

  &:nth-of-type(2) {
    border-bottom: $border-width-small solid $huetown-purple;
  }

  &:nth-of-type(3) {
    border-bottom: $border-width-small solid $dark-red;
  }
}

.paypal-cart-form {
  border-bottom: $border-width-small solid $orange-muted;
}

.nav-link,
.paypal-cart-form input.paypal-link {
  display: block;
  width: 100%;
  padding: 1.5rem 0;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: .125rem;
  text-decoration: none;
  text-transform: capitalize;
  background-color: transparent;
  color: $white;
  cursor: pointer;
}

.nav-menu-icons {
  display: flex;
  justify-content: center;
  padding: .25rem;
  border-bottom: $border-width-small solid $fifty-percent-gray;

}

.icon-link-container {
  margin: 1rem 2rem;
}

.icon-link img {
  width: 2rem;
  vertical-align: top;
}

/* Media Queries */

@media (min-width: $breakpoint-sm) {

  header {
    position: relative;
    padding-bottom: .75rem;
    background-color: $white;
  }

  .huetown-logo-and-burger-button {
    justify-content: center;
    background-color: $white;
  }

  nav {
    flex-direction: row;
    justify-content: space-around;
    max-width: 36rem;
    margin: auto;
    background-color: $white;
    backdrop-filter: initial;

    &.mobile-nav-is-closed {
      display: flex;
    }
  }

  .nav-link-wrapper:nth-of-type(n+1),
  .paypal-cart-form {
    border: none;
  }

  .nav-link,
  .paypal-cart-form input.paypal-link {
    padding: 0 0 .25rem;
    border-bottom: $border-width-large solid transparent;
    transition: all 0.125s linear;
    font-weight: 500;
    color: $application-font-color;

    &:hover,
    &.nav-link-active {
      padding: initial;
      border-bottom: $border-width-large solid $fifty-percent-gray;
      margin-bottom: .25rem;
    }
  }
}
