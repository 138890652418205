@use './variables' as *;

html {
  scroll-behavior: smooth;
}

body {
  max-width: 87.5rem;
  margin: auto;
  color: $application-font-color;
}

main {
  position: relative;
  top: 60px;
  display: flex;
  justify-content: center;
  padding: .5rem auto;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5rem;
}

.text-link {
  text-decoration: none;
  color: $dark-kelly-green;
  font-weight: 600;

  &:link,
  &:visited,
  &:active {
    color: $dark-kelly-green;
  }

  &.shop-link {
    color: $application-font-color;
    &:link,
    &:visited,
    &:active {
      color: $application-font-color;
    }
  }

  &:hover:not(.shop-link) {
    color: $twenty-four-plum-paper;
    text-decoration: underline;
    cursor: pointer;
  }
}

.ital {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

.not-found-error {
  text-align: center;
}

/* Media Queries */

@media (min-width: $breakpoint-sm) {

  main {
    top: initial;
  }
}
