@use './_variables' as *;

.about-content {
  width: $page-width;
  margin-top: .5rem;
}

.about-upper div {
  position: relative;
  bottom: .25rem;

  span.bold {
    font-weight: 600;
  }
}

.self-portrait {
  width: $about-image-dimension;
  margin-right: 1rem;
  float: left;
}

.huetown-h-logo {
  display: none;
  width: $about-image-dimension;
}

.about-lower div {
  margin-top: .75rem;
}

.section-title {
  font-weight: 600;
  text-transform: capitalize;
}

.mailchimp-form-container form {
  padding-bottom: 1.5rem;
}

.subscribe-prompt {
  margin: 1.5rem auto 1rem;

  div:first-child {
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.required {
  color: $red;
}

.form-input {
  width: 95%;
  max-width: 25rem;
  padding: .5rem;
  margin-bottom: 1rem;
}

.form-status {
  margin-bottom: .5rem;
  font-weight: 600;
}

.form-status-success {
  color: $dark-kelly-green;
}

.form-status-error {
  color: $red;
}

.subscribe-button {
  border: $border-width-medium solid transparent;
  border-radius: $border-radius-small;
  padding: .5rem 1rem;
  margin-top: .5rem;
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: $sixty-seven-percent-gray;
  color: $white;
  letter-spacing: .3125rem;
  text-transform: capitalize;

  &:hover {
    border-color: $sixty-seven-percent-gray;
    background-color: $rainbow-yellow;
    color: $black;
  }
}

/* Media Queries */

@media (min-width: $breakpoint-sm) {

  .about-upper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .huetown-h-logo {
    display: block;
    margin-left: 1rem;
  }
}
